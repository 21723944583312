import {createContext, useContext, useState} from "react";
import {useAuth} from "./useAuth";
import {sagRequest} from "../utils/request-utils";


const SAGContext = createContext(null);

export const SAGProvider = ({ children }) => {
    const {user, logout} = useAuth()
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const request = async (method, url, body = null) => {
        try {
            setError(null);
            setLoading(true);
            const ret =  await sagRequest(user.accessToken, method, url, body);
            setLoading(false);
            return ret;
        } catch (e) {
            setLoading(false);
            if (e.message === "Not logged in") {
                logout();
            }
            setError(e.message);
            throw e;
        }
    }

    const clearError = () => { setError(null); }

    const value = {
        request,
        loading,
        error,
        clearError,
    };

    return <SAGContext.Provider value={value}>{children}</SAGContext.Provider>;
};

export const useSAG = () =>{
    return useContext(SAGContext);
}