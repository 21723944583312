import {Button, Tooltip} from "@mui/material";
import ListIcon from '@mui/icons-material/List';
import {Link, Outlet, Route} from "react-router-dom";
import AddCompany, { action as createAction } from "./AddCompany";
import StoreIcon from '@mui/icons-material/Store';
import ListCompanies from "./ListCompanies";
import {getHost} from "../../utils/request-utils";
import ViewCompany from "./ViewCompany";
import EditCompany, { action as editAction } from "./EditCompany";

export function CompaniesRoutes(props) {
    return (
        <Route path="/companies" id="companies" element={<CompaniesPage/>} loader={companiesLoader(props)}>
            <Route index element={<ListCompanies />}/>
            <Route path="add" element={<AddCompany />} action={createAction(props)} />
            <Route path=":companyID" element={<ViewCompany />}/>
            <Route path=":companyID/edit" element={<EditCompany />} action={editAction(props)}/>
        </Route>
    )
}

const companiesLoader = ({apiClient, portalData}) => async() => {
    const u = new URL(getHost() + "/api/v1/companies");
    const companies = await apiClient.get(u).then(res => res.data.data ?? []);

    return {companies};
}

function CompaniesPage() {
    return (
        <>
            <h1>Companies</h1>
            <div className="toolbar">
                <Tooltip title={"Ny virksomhed"}>
                    <Link to="add"><Button variant="contained">+<StoreIcon/></Button></Link>
                </Tooltip>
                <Tooltip title={"List virksomheder"}>
                    <Link to="/companies"><Button variant="contained"><ListIcon/></Button></Link>
                </Tooltip>
            </div>
            <Outlet />
        </>
    )
}