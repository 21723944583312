import {Link, useParams, useRouteLoaderData} from "react-router-dom";


export default function ViewCompany() {

    const {companies} = useRouteLoaderData("companies");
    const {companyID} = useParams();
    const company = companies.find(m => m.id === companyID);

    return (
        <>
            Viewing company<br />
            Navn: {company.name}<br />
            <Link to="edit">[edit]</Link>
        </>);
}