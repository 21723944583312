import {useNavigate, useRouteLoaderData} from "react-router-dom";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";


export default function ListCompanies() {

    const {companies} = useRouteLoaderData("companies");
    const navigate = useNavigate();

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Navn</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {companies.map(elm => (
                    <TableRow style={{cursor:"pointer"}} key={elm.id} onClick={() => navigate(elm.id)}>
                        <TableCell>{elm.name}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}