import {Link as RouterLink, useNavigate, useNavigation, useOutlet, useRouteLoaderData} from "react-router-dom";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StoreIcon from '@mui/icons-material/Store';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import PeopleIcon from '@mui/icons-material/People';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import {useAuth} from "../hooks/useAuth";
import {useCompany} from "../hooks/useCompany";
import {AppBar, Divider, IconButton, Menu, MenuItem, Toolbar} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import ApartmentIcon from '@mui/icons-material/Apartment';
import {useState} from "react";
import AccountCircle from '@mui/icons-material/AccountCircle';


const drawerWidth = 240;

export const Layout = () => {
    const outlet = useOutlet();
    const {user, logout} = useAuth();
    const navigation = useNavigation();
    const {companyID, setCompanyID} = useCompany();
    const [mobileOpen, setMobileOpen] = useState(false);
    const {userInfo, userCompanies} = useRouteLoaderData("user");
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const companyList = userCompanies.map(u => ({
        ...u,
        value: u.id,
        label: u.name,
    }))

    const company = userCompanies.find(elm => elm.id === companyID);

    const links = <Box sx={{overflow: 'auto'}}>
        {!!user &&
            <>
            <List>

                {company &&
                    <ListItem
                        disablePadding
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                    >
                        <ListItemButton>

                            <ListItemIcon><AccountCircle/></ListItemIcon>
                            <ListItemText primary={company.name}></ListItemText>
                        </ListItemButton>
                    </ListItem>
                }
                {!company &&
                    <ListItem
                        disablePadding
                        component={RouterLink}
                        to="/companies/add"
                        onClick={() => setMobileOpen(false)}
                    >
                        <ListItemButton>
                            <ListItemIcon><ApartmentIcon/></ListItemIcon>
                            <ListItemText primary="Brokers"/>
                        </ListItemButton>
                    </ListItem>
                }

                {userCompanies.length > 0 &&
                    <>
                        <Divider />
                        <ListItem
                            disablePadding
                            component={RouterLink}
                            to="/companies"
                            onClick={() => setMobileOpen(false)}
                        >
                            <ListItemButton>
                                <ListItemIcon><StoreIcon/></ListItemIcon>
                                <ListItemText primary="Virksomheder"/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem
                            disablePadding
                            component={RouterLink}
                            to="/salaryConnections"
                            onClick={() => setMobileOpen(false)}
                        >
                            <ListItemButton>
                                <ListItemIcon><CurrencyExchangeIcon/></ListItemIcon>
                                <ListItemText primary="Lønsystemforbindelser"/>
                            </ListItemButton>
                        </ListItem>
                    </>
                }
            </List>

            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                {userCompanies.map(elm => <MenuItem key={elm.id} onClick={() => {setMobileOpen(false); setAnchorEl(null); setCompanyID(elm.id); navigate('/')}}>{elm.name}</MenuItem>)}
                <Divider />


                <MenuItem onClick={() => {setMobileOpen(false); setAnchorEl(null); logout();}}>
                    <ListItemIcon><LogoutIcon/></ListItemIcon>
                    <ListItemText>Log ud</ListItemText>
                </MenuItem>
            </Menu>


            </>
        }




        {!user &&
            <List>
                <ListItem
                    disablePadding
                    component={RouterLink}
                    to="/login"
                    onClick={() => setMobileOpen(false)}
                >
                    <ListItemButton>
                        <ListItemIcon><LoginIcon/></ListItemIcon>
                        <ListItemText primary="Log ind"/>
                    </ListItemButton>
                </ListItem>
                <ListItem><ListItemText>{navigation.state}</ListItemText></ListItem>
            </List>
        }

    </Box>


    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>

            <AppBar
                position="fixed"
                sx={{
                    display: { sm: 'none' },
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                {links}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box'},
                }}
            >
                {links}
            </Drawer>
            <Box component="main" sx={{flexGrow: 1, p: 3}}>
                <Toolbar sx={{display: { sm: 'none' }}} />
                {outlet}
            </Box>
        </Box>
    )
}