import {redirect} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import SAGForm from "../../utils/SAGForm";
import {Button, FormControl, TextField} from "@mui/material";
import Box from "@mui/material/Box";

import {getHost} from "../../utils/request-utils";


export const action = ({apiClient, portalData}) => async({request}) => {
    const formData = Object.fromEntries(await request.formData());

    const data = {
        ...formData,
        brokerID: portalData.brokerID,
    }

    const u = new URL(getHost() + "/api/v1/companies");
    const ret = await apiClient.post(u, data);
    return redirect('/companies/' + ret.data.data.id);
}

export default function AddCompany() {

    const { handleSubmit, control, formState: {errors} } = useForm();

    return (
        <Box sx={{width: 600}}>
            <SAGForm method="POST" handleSubmit={handleSubmit}>
                <Controller
                    name="name"
                    control={control}
                    rules={{required: true}}
                    defaultValue={''}
                    render={({field}) => <TextField margin="normal" error={!!errors.name} fullWidth label="Navn" {...field} />}
                />
                <FormControl fullWidth  margin="normal">
                    <Button type="submit" variant="contained">Opret</Button>
                </FormControl>
            </SAGForm>
        </Box>
    )
}