import {getHost} from "../../utils/request-utils";
import {redirect, useParams, useRouteLoaderData} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import SAGForm from "../../utils/SAGForm";
import Box from "@mui/material/Box";
import {Button, FormControl, TextField} from "@mui/material";


export const action = ({apiClient, portalData}) => async({request, params}) => {
    const formData = Object.fromEntries(await request.formData());

    const data = {
        ...formData,
        brokerID: portalData.brokerID,
    }

    const u = new URL(getHost() + "/api/v1/companies/" + params.companyID);
    await apiClient.put(u, data);
    return redirect('/companies/' + params.companyID);
}

export default function EditCompany() {
    const {companies} = useRouteLoaderData("companies");
    const {companyID} = useParams();
    const company = companies.find(l => l.id === companyID);

    const { handleSubmit, control, formState: {errors} } = useForm();

    return (
        <Box
            sx={{width: 600}}
            >
            <SAGForm method="POST" handleSubmit={handleSubmit}>
                <Controller
                    name="name"
                    control={control}
                    rules={{required: true}}
                    defaultValue={company.name ?? ''}
                    render={({field}) => <TextField margin="normal" error={!!errors.name} fullWidth label="Navn" {...field} />}
                    />
                <FormControl fullWidth  margin="normal">
                    <Button type="submit" variant="contained">Gem</Button>
                </FormControl>
            </SAGForm>
        </Box>
    )
}