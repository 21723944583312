import './App.css';
import {Navigate, useRouteLoaderData} from "react-router-dom";
import {useAuth} from "./hooks/useAuth";

export default function App() {
    const { user } = useAuth();
    const { userBrokers } = useRouteLoaderData("user");

    if (!user) {
        return <Navigate to="/login" />;
    }

    // determine where to go after login
    // if (userCompanies.length === 0) {
    //     return <Navigate to="/companies/add" />;
    // }
    return <Navigate to={"/dashboard"} />; // initial navigation after login
}
