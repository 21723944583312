import {Button, Tooltip} from "@mui/material";
import ListIcon from '@mui/icons-material/List';
import {Link, Outlet, Route} from "react-router-dom";
import AddSalaryConnection, { action as createAction } from "./AddSalaryConnection";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ListSalaryConnections from "./ListSalaryConnections";
import {getHost} from "../../utils/request-utils";
import ViewSalaryConnection, {deleteSalaryConnection} from "./ViewSalaryConnection";
import EditSalaryConnection, { action as editAction } from "./EditSalaryConnection";
import EditTestConnection, {action as updateTestConnection, testConnectionLoader} from "./EditTestConnection";

export function SalaryConnectionsRoutes(props) {
    return (
        <Route path="/salaryConnections" id="salaryConnections" element={<SalaryConnectionsPage/>} loader={companiesLoader(props)}>
            <Route index element={<ListSalaryConnections />}/>
            <Route path="add" element={<AddSalaryConnection />} action={createAction(props)} />
            <Route path=":salaryConnectionID" element={<ViewSalaryConnection />}/>
            <Route path=":salaryConnectionID/edit" element={<EditSalaryConnection />} action={editAction(props)}/>
            <Route path=":salaryConnectionID/delete" action={deleteSalaryConnection(props)}/>
            <Route path=":salaryConnectionID/testConnection" element={<EditTestConnection />} action={updateTestConnection(props)} loader={testConnectionLoader(props)}/>
        </Route>
    )
}

const companiesLoader = ({apiClient, companyID}) => async() => {
    const u = new URL(getHost() + "/api/v1/salaryConnections?companyID=" + companyID);
    const salaryConnections = await apiClient.get(u).then(res => res.data.data ?? []);

    return {salaryConnections};
}

function SalaryConnectionsPage() {
    return (
        <>
            <h1>Lønsystemforbindelser</h1>
            <div className="toolbar">
                <Tooltip title={"Ny forbindelse"}>
                    <Link to="add"><Button variant="contained">+<CurrencyExchangeIcon/></Button></Link>
                </Tooltip>
                <Tooltip title={"List forbindelser"}>
                    <Link to="/salaryConnections"><Button variant="contained"><ListIcon/></Button></Link>
                </Tooltip>
            </div>
            <Outlet />
        </>
    )
}