import {Form, useSubmit} from "react-router-dom";

// Wraps the validation of react-hook-form with react-router's Form
export default function SAGForm(props) {

    const submit = useSubmit();

    async function onSubmit(event) {
        // Prevent the normal form submit action
        event.preventDefault();
        // Use react-hook-form's validator, and do a manual submit if the validation passes.
        await props.handleSubmit(async() => await submit(event.target))();
    }

    return (
        <Form method={props.method} onSubmit={onSubmit} action={props.action}>
            {props.children}
        </Form>
    )

}