export const promiseAllNamed = (nameToPromise) => {
    const entries = Object.entries(nameToPromise);
    return Promise.all(entries.map(e => e[1]))
        .then(results => {
            const nameToResult = {};
            for (let i = 0; i < results.length; ++i) {
                const name = entries[i][0];
                nameToResult[name] = results[i];
            }
            return nameToResult;
        });
};