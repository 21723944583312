import {Navigate, redirect, useLoaderData, useNavigate, useOutlet} from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import {getHost} from "../utils/request-utils";
import {promiseAllNamed} from "../utils/promiseAllNamed";
import {useBroker, useCompany} from "../hooks/useCompany";
import {useEffect} from "react";

export const userLoader = ({apiClient}) => async() => {

    const userInfo = apiClient.get(new URL(getHost() + "/api/v1/login")).then(res => res.data.data);
    const userCompanies = apiClient.get(new URL(getHost() + "/api/v1/companies")).then(res => res.data.data ?? []);

    return promiseAllNamed({userInfo, userCompanies}).catch(e => {
        if (e.response && e.response.status === 401) {
            // not logged in when getting some information
            throw redirect("/login");
        }
    });

}

export const ProtectedLayout = () => {
    const { user } = useAuth();
    const outlet = useOutlet();
    const {companyID, setCompanyID} = useCompany();
    const {userCompanies} = useLoaderData();

    useEffect(() => {
        if (!user) {
            return;
        }
        if (!companyID && userCompanies.length > 0) {
            // Has no broker selected, selecting the first one.
            setCompanyID(userCompanies[0].id);
        }

        if (companyID && userCompanies.length > 0 && !userCompanies.find(c => c.id === companyID)) {
            // Has unknown broker selected, selecting the first one.
            setCompanyID(userCompanies[0].id)
        }

    }, [companyID, userCompanies])

    if (!user) {
        // not logged in, send to login page
        return <Navigate to="/login" />;
    }

    return (
        <div>
            {outlet}
        </div>
    );
};
