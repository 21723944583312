import {createContext, useContext, useEffect, useMemo, useState} from "react";
import { useLocalStorage } from "./useLocalStorage";
import {getHost} from "../utils/request-utils";
import {useCompany} from "./useCompany";

const AuthContext = createContext(null);

export const AuthProvider = ({ children, apiClient }) => {
    const [user, setUser] = useLocalStorage("user", {});
    const {companyID, setCompanyID, portalData} = useCompany();


    const login = async (data) => {
        // try to log in
        const d = {
            email: data.email,
            password: data.password,
            brokerID: portalData.brokerID,
        };
        let u = new URL(getHost() + "/api/v1/login");
        const ret = await apiClient.post(u, d);
        setUser(ret.data.data);
        setCompanyID(null);

        window.localStorage.setItem("accessToken", ret.data.data.accessToken);
    };

    const logout = () => {
        setUser(null);
        setCompanyID(null);
        window.localStorage.removeItem("accessToken");
    };

    const value = useMemo(
        () => ({
            user,
            login,
            logout,
        }),
        [user]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
