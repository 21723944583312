import {redirect} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import SAGForm from "../../utils/SAGForm";
import {Button, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import Box from "@mui/material/Box";

import {getHost} from "../../utils/request-utils";


export const action = ({apiClient, companyID}) => async({request}) => {
    const formData = Object.fromEntries(await request.formData());

    const data = {
        ...formData,
        companyID: companyID,
    }

    const u = new URL(getHost() + "/api/v1/salaryConnections");
    const ret = await apiClient.post(u, data);
    return redirect('/salaryConnections/' + ret.data.data.id);
}

export default function AddSalaryConnection() {

    const { handleSubmit, control, formState: {errors} } = useForm();

    return (
        <Box sx={{width: 600}}>
            <SAGForm method="POST" handleSubmit={handleSubmit}>
                <Controller
                    name="apiKey"
                    control={control}
                    defaultValue={''}
                    render={({field}) => <TextField margin="normal" error={!!errors.apiKey} fullWidth label="APIKey" {...field} />}
                />
                <Controller
                    name="username"
                    control={control}
                    defaultValue={''}
                    render={({field}) => <TextField margin="normal" error={!!errors.username} fullWidth label="Brugernavn" {...field} />}
                />
                <Controller
                    name="password"
                    control={control}
                    defaultValue={''}
                    render={({field}) => <TextField margin="normal" error={!!errors.password} fullWidth label="Adgangskode" {...field} />}
                />
                <Controller
                    name="connectionType"
                    control={control}
                    defaultValue={''}
                    render={({field}) => <FormControl fullWidth margin="normal">

                        <InputLabel id="connectionTypeLabel">Lønsystem</InputLabel>
                        <Select
                            id="connectionType"
                            labelId="connectionTypeLabel"
                            label="Lønsystem"
                            {...field}
                        >
                            <MenuItem value="Test">Test</MenuItem>
                            <MenuItem value="SalaryDK">SalaryDK</MenuItem>
                        </Select>
                    </FormControl>}
                />
                <FormControl fullWidth  margin="normal">
                    <Button type="submit" variant="contained">Opret</Button>
                </FormControl>
            </SAGForm>
        </Box>
    )
}