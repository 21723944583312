import {Navigate, useNavigate, useRouteError} from "react-router-dom";
import {useAuth} from "./hooks/useAuth";
import {useEffect} from "react";


export default function ErrorPage() {
    const error = useRouteError();
    const {logout} = useAuth();

    useEffect(() => {
        if (error.response?.status === 401) {
            logout();
        }
    }, [])

    if (error.response?.status === 401) {
        return <Navigate to="/" />
    }

    return (
        <div className="errorPage">
            <h1>Error!</h1>
            <p>Some error</p>
            <p>{error && (error.statusText || error.message)}</p>
        </div>
    )
}