import {useNavigate, useRouteLoaderData} from "react-router-dom";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";


export default function ListSalaryConnections() {

    const {salaryConnections} = useRouteLoaderData("salaryConnections");
    const navigate = useNavigate();

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Status</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {salaryConnections.map(elm => (
                    <TableRow style={{cursor:"pointer"}} key={elm.id} onClick={() => navigate(elm.id)}>
                        <TableCell>{elm.id}</TableCell>
                        <TableCell>{elm.connectionType}</TableCell>
                        <TableCell>{elm.state}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}