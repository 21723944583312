import {redirect, useLoaderData, useParams, useRouteLoaderData} from "react-router-dom";
import {getHost} from "../../utils/request-utils";
import {useForm, Controller} from "react-hook-form";
import SAGForm from "../../utils/SAGForm";
import {Button, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import Box from "@mui/material/Box";


export const action = ({apiClient, companyID}) => async({request, params}) => {
    const formData = Object.fromEntries(await request.formData());

    const data = {
        ...formData,
    }

    const u = new URL(getHost() + "/api/v1/testConnections/" + params.salaryConnectionID);
    await apiClient.put(u, data);
    return redirect('/salaryConnections/' + params.salaryConnectionID);
}

export const testConnectionLoader = ({apiClient, companyID}) => async({params}) => {
    const u = new URL(getHost() + "/api/v1/testConnections/" + params.salaryConnectionID);
    const testConnection = await apiClient.get(u).then(res => res.data.data ?? []);

    return {testConnection}
}

export default function EditTestConnection() {
    const {salaryConnections} = useRouteLoaderData("salaryConnections");
    const {salaryConnectionID} = useParams();
    const {testConnection} = useLoaderData();

    const { handleSubmit, control, formState: {errors} } = useForm();

    return (
        <Box sx={{width: 600}}>
            <SAGForm method="POST" handleSubmit={handleSubmit}>

                <Controller
                    name="state"
                    control={control}
                    defaultValue={testConnection.state ?? 'Failure'}
                    render={({field}) => <FormControl fullWidth margin="normal">

                        <InputLabel id="stateLabel">Tilstand</InputLabel>
                        <Select
                            id="state"
                            labelId="stateLabel"
                            label="Tilstand"
                            {...field}
                        >
                            <MenuItem value="Failure">Failure</MenuItem>
                            <MenuItem value="Active">Active</MenuItem>
                        </Select>
                    </FormControl>}
                />

                <Controller
                    name="latestPayRollInformation"
                    control={control}
                    defaultValue={testConnection.latestPayRollInformation ?? '{}'}
                    render={({field}) => <TextField martin="normal" error={!!errors.latestPayRollInformation} fullWidth multiline maxRows={10} label="Senest lønkørseldata" {...field} />}
                />


                <FormControl fullWidth  margin="normal">
                    <Button type="submit" variant="contained">Gem</Button>
                </FormControl>
            </SAGForm>
        </Box>
    )
}