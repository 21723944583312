import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import {ProtectedLayout, userLoader} from "./components/ProtectedLayout";
import {LoginPage} from "./components/Login/LoginPage";
import {registerLocale} from "react-datepicker";
import localedk from 'date-fns/locale/da';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Layout} from "./components/Layout";
import CssBaseline from "@mui/material/CssBaseline";
import axios from "axios";
import ErrorPage from "./ErrorPage";
import {CompanyProvider} from "./hooks/useCompany";
import {AuthProvider} from "./hooks/useAuth";
import {SAGProvider} from "./hooks/useSAG";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {useLocalStorage} from "./hooks/useLocalStorage";
import {CompaniesRoutes} from "./components/Companies/CompaniesPage";
import {getHost} from "./utils/request-utils";
import Dashboard from "./components/Dashboard/Dashboard";
import {SalaryConnectionsRoutes} from "./components/SalaryConnections/SalaryConnectionsPage";


function newApiClient() {
    return axios.create({
        baseURL: "http://localhost:3100",
        headers: {
            "Content-Type": "application/json",
        }
    })
}

const apiClient = newApiClient();

// set up header
apiClient.interceptors.request.use(
    (config) => {

        const accessToken = window.localStorage.getItem("accessToken");
        if (accessToken) {
            config.headers["Authorization"] = accessToken;
        }

        return config;
    }
);

// Look up data for the portal
(async () => {
    const params = new URLSearchParams(document.location.search);
    let brokerID = params.get('brokerID');
    let hostname = undefined;
    if (!brokerID) {
        brokerID = sessionStorage.getItem('sagPortalBrokerID');
        if (!brokerID) {
            hostname = params.get('hostname')
            if (!hostname) {
                hostname = document.location.hostname;
            }
        }
    }

    let u = getHost() + "/api/v1/portalSettings";
    if (brokerID) {
        u += "?brokerID=" + encodeURIComponent(brokerID);
    } else {
        u += '?hostname=' + encodeURIComponent(hostname);
    }

    const response = await fetch(u);
    if (!response.ok) {
        document.write("Could not open portal");
        throw Error("Could not open portal");
    }
    const ret = await response.json()
    sessionStorage.setItem('sagPortalBrokerID', ret.data.brokerID);
    return ret.data;

})().then(portalData => {

    registerLocale("da", localedk);


    const root = ReactDOM.createRoot(document.getElementById('root'));

    const SAGRouterProvider = () => {

        const [companyID, setCompanyID] = useLocalStorage("sagCompanyID", null);

        // Until this is resolved, we make our own context: https://github.com/remix-run/react-router/discussions/9856
        const context = {
            apiClient,
            companyID,
            setCompanyID,
            portalData,
        };

        const router = createBrowserRouter(createRoutesFromElements(
            <Route errorElement={<ErrorPage />}>
                <Route path="/login" element={<LoginPage/>}/>
                <Route element={<ProtectedLayout/>} loader={userLoader(context)} id="user">
                    <Route element={<Layout/>} >
                        <Route index element={<App/>}/>
                        <Route path="/dashboard" element={<Dashboard />}/>
                        {CompaniesRoutes(context)}
                        {SalaryConnectionsRoutes(context)}
                    </Route>
                </Route>
            </Route>
        ));

        return (
            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="da">
                <CompanyProvider context={context}>
                    <AuthProvider apiClient={apiClient}>
                        <SAGProvider>
                            <RouterProvider router={router} />
                        </SAGProvider>
                    </AuthProvider>
                </CompanyProvider>
            </LocalizationProvider>
        )
    };

    root.render(
        <React.StrictMode>
            <CssBaseline />
            <SAGRouterProvider />
        </React.StrictMode>
    );

}).catch(e => {
    console.log(e);
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
