import {getHost} from "../../utils/request-utils";
import {redirect, useParams, useRouteLoaderData} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import SAGForm from "../../utils/SAGForm";
import Box from "@mui/material/Box";
import {Button, FormControl, TextField} from "@mui/material";


export const action = ({apiClient, companyID}) => async({request, params}) => {
    const formData = Object.fromEntries(await request.formData());

    const data = {
        ...formData,
        companyID: companyID,
    }

    const u = new URL(getHost() + "/api/v1/salaryConnections/" + params.salaryConnectionID);
    await apiClient.put(u, data);
    return redirect('/salaryConnections/' + params.salaryConnectionID);
}

export default function EditSalaryConnection() {
    const {salaryConnections} = useRouteLoaderData("salaryConnections");
    const {salaryConnectionID} = useParams();
    const salaryConnection = salaryConnections.find(l => l.id === salaryConnectionID);

    const { handleSubmit, control, formState: {errors} } = useForm();

    return (
        <Box
            sx={{width: 600}}
            >
            <SAGForm method="POST" handleSubmit={handleSubmit}>
                <Controller
                    name="apiKey"
                    control={control}
                    defaultValue={salaryConnection.apiKey ?? ''}
                    render={({field}) => <TextField margin="normal" error={!!errors.apiKey} fullWidth label="APIKey" {...field} />}
                />
                <Controller
                    name="username"
                    control={control}
                    defaultValue={salaryConnection.username ?? ''}
                    render={({field}) => <TextField margin="normal" error={!!errors.username} fullWidth label="Brugernavn" {...field} />}
                />
                <Controller
                    name="password"
                    control={control}
                    defaultValue={salaryConnection.password ?? ''}
                    render={({field}) => <TextField margin="normal" error={!!errors.password} fullWidth label="Adgangskode" {...field} />}
                />
                <FormControl fullWidth  margin="normal">
                    <Button type="submit" variant="contained">Gem</Button>
                </FormControl>
            </SAGForm>
        </Box>
    )
}