import {Link, redirect, useFetcher, useParams, useRouteLoaderData} from "react-router-dom";
import {Button} from "@mui/material";
import {getHost} from "../../utils/request-utils";

export const deleteSalaryConnection = ({apiClient}) => async({request, params}) => {
    await apiClient.delete(new URL(getHost() + "/api/v1/salaryConnections/" + params.salaryConnectionID))
    return redirect('/salaryConnections');
}


export default function ViewSalaryConnection() {

    const {salaryConnections} = useRouteLoaderData("salaryConnections");
    const {salaryConnectionID} = useParams();
    const salaryConnection = salaryConnections.find(m => m.id === salaryConnectionID);
    const fetcher = useFetcher();


    async function deleteSalaryConnection() {
        fetcher.submit(
            {
                salaryConnectionID: salaryConnectionID,
            },
            {
                method: 'post',
                action: "/salaryConnections/" + salaryConnectionID + "/delete",
            }
        )
    }

    return (
        <>
            Viser lønsystemforbindelse<br />
            ID: {salaryConnection.ID}<br />
            Type: {salaryConnection.connectionType}<br />
            Status: {salaryConnection.state}<br />
            {salaryConnection.state === 'Failed' && <>Error: {salaryConnection.errorMessage}<br /></>}
            <Link to="edit">[edit]</Link>
            <Button onClick={deleteSalaryConnection}>[delete]</Button>

            {salaryConnection.connectionType === "Test" && <Link to="testConnection">[test]</Link>}
        </>);
}