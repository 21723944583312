import * as React from "react";
import { useAuth } from "../../hooks/useAuth";
import {Link, useNavigate} from "react-router-dom";
import {Button, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import {useEffect} from "react";

export const LoginPage = () => {
    const { login, user, logout } = useAuth();
    const navigate = useNavigate();


    useEffect(() => {
        if (user) {
            logout();
        }
    }, []);


    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        await login({
            email: data.get("email"),
            password: data.get("password")
        });
        navigate('/');
    };

    return (
        <Box
            style={{
                'position': 'fixed',
                'width': '100vw',
                'height': '100vh',
                'overflow': 'auto',
                'display': 'flex',
                'flexDirection': 'column',
                'left': 0,
                'top': 0,
                'backgroundColor': 'rgba(0,0,0,0.4)',
            }}
            >
            <Box
            sx={{
                'margin': 'auto',
                'backgroundColor': 'white',
                'position': 'relative',
                'padding': '20px 40px',
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            component="form" onSubmit={handleSubmit} noValidate>
            <div>
            <TextField name="email" size="small" label="Email" />
            </div>
            <div>
            <TextField type="password" name="password" size="small" label="Password" />
            </div>
            <div style={{'display': 'flex', 'justifyContent': 'space-between'}}>
                <Button type="submit" variant="contained">Log in</Button>
                <Link to="/register"><Button variant="outline">Sign up</Button></Link>
            </div>
        </Box>
        </Box>
    );
};
