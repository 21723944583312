import {createContext, useContext} from "react";

const CompanyContext = createContext(null);

export const CompanyProvider = ({ children, context }) => {

    const value = {
        ...context
    };

    return <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>;
};


export const useCompany = () => {
    return useContext(CompanyContext);
}